import { Card } from './Card'
import { CardContainer } from './CardContainer'

type Props = {
  firstOfRow?: boolean
}

export function EmptyDay(props: Props) {
  return (
    <CardContainer firstOfRow={props.firstOfRow}>
      <Card disabled />
    </CardContainer>
  )
}
