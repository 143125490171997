import styled from 'styled-components'

import { applyAlphaToColor } from '@/local/components'

export const navigationButtonWidth = 20

export const NavigationButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${navigationButtonWidth}px;
  height: ${navigationButtonWidth}px;

  padding: 0;
  margin: 0;
  outline: 0;

  border-width: 0;
  border-radius: 4px;

  background: transparent;
  color: ${p => (p.disabled ? p.theme.colors.$whiteSmoke : p.theme.colors.$nightRider)};
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};

  transition: background 0.2s ease, color 0.2s ease;

  &:hover {
    color: ${p => (p.disabled ? p.theme.colors.$shadyLady : p.theme.colors.$scienceBlue)};
    background: ${p =>
      p.disabled ? 'transparent' : applyAlphaToColor(p.theme.colors.$scienceBlue, 0.1)};
  }
`
NavigationButton.displayName = 'NavigationButton'
