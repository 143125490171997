import type { Days } from '../typings'

import { useMemo } from 'react'
import { getDay } from 'date-fns'

import { HorizontalLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { useDateFnsOptions } from '@/hooks'

import { useDaysOfWeek } from '../hooks/useDaysOfWeek'
import { monthWidth } from '../constants'

import { Card } from './Card'
import { CardContainer } from './CardContainer'

interface Props {
  actualDays: Days
}
const getDaysOfWeek = () => [0, 1, 2, 3, 4, 5, 6]
export function DaysOfWeek(props: Props) {
  const { actualDays } = props

  const { weekStartsOn } = useDateFnsOptions()
  const days = useDaysOfWeek()

  const todayDayOfWeek = useMemo(() => {
    // Ex => startOfWeek = 6 | [0, 1, 2, 3, 4, 5, 6] => [6, 0, 1, 2, 3, 4, 5]
    const shiftedDaysOfWeek = getDaysOfWeek()
      .slice(weekStartsOn)
      .concat(getDaysOfWeek().splice(0, weekStartsOn))

    const today = actualDays.find(d => d.type === 'day' && d.isToday)

    if (!today || today.type !== 'day') return -1

    return shiftedDaysOfWeek.indexOf(getDay(today.date))
  }, [actualDays, weekStartsOn])

  return (
    <HorizontalLayout width={monthWidth}>
      {days.map((d, index) => (
        <CardContainer firstOfRow={index === 0} key={d}>
          <Card disabled>
            <Text
              size="$s"
              weight={todayDayOfWeek === index ? '$bold' : '$regular'}
              color={todayDayOfWeek === index ? '$pureBlack' : '$silver'}
            >
              {d}
            </Text>
          </Card>
        </CardContainer>
      ))}
    </HorizontalLayout>
  )
}
