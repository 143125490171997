import type { CardCosmetic } from '../../typings'
import type { ValidColor } from '@/local/components'

import styled from 'styled-components'

type Props = {
  background: ValidColor
  cosmetic?: CardCosmetic
}

export const Dot = styled.span<Props>`
  position: absolute;
  bottom: 0;
  left: 50%;

  width: 3px;
  height: 3px;
  border-radius: 50%;

  background: ${p =>
    p.cosmetic === 'edge-of-selection'
      ? p.theme.colors.$pureWhite
      : p.theme.colors[p.background] || 'black'};

  transform: translate(-50%, -3px);

  z-index: 5;
`
Dot.displayName = 'Dot'
