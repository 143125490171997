import type { CardCosmetic } from '../typings'

import styled from 'styled-components'

import { applyAlphaToColor } from '@/local/components'
import { cardTopSpace, cardGutter } from '../constants'

type Props = {
  cosmetic?: CardCosmetic
  roundedBorder?: 'left' | 'right' | 'none'
  firstOfRow?: boolean
  selectionStart?: boolean
  ghostSelectionStart?: boolean
  ghostSelectionEnd?: boolean
  selectionEnd?: boolean
  forceUnselected?: boolean
}

export const CardContainer = styled.span<Props>`
  display: block;
  position: relative;
  padding: 0;
  outline: 0;
  margin: 0;
  border-width: 0;

  border-top-left-radius: ${p => (p.roundedBorder === 'left' ? 3 : 0)}px;
  border-bottom-left-radius: ${p => (p.roundedBorder === 'left' ? 3 : 0)}px;

  border-top-right-radius: ${p => (p.roundedBorder === 'right' ? 3 : 0)}px;
  border-bottom-right-radius: ${p => (p.roundedBorder === 'right' ? 3 : 0)}px;

  background: ${p =>
    (p.cosmetic === 'selected' || (p.selectionEnd && !p.selectionStart)) && !p.forceUnselected
      ? applyAlphaToColor(p.theme.colors.$scienceBlue, 0.1)
      : p.cosmetic === 'ghosted' && !p.ghostSelectionStart
      ? applyAlphaToColor(p.theme.colors.$scienceBlue, 0.1)
      : 'transparent'};

  padding-left: ${p => (p.firstOfRow ? 0 : cardGutter)}px;
  margin-top: ${cardTopSpace}px;

  z-index: 2;
`

CardContainer.displayName = 'CardContainer'
