import type { CardCosmetic } from '../typings'

import styled from 'styled-components'

import { applyAlphaToColor } from '@/local/components'

import { cardSize } from '../constants'

type Props = {
  size?: number
  cosmetic?: CardCosmetic
  ghostSelectionEnd?: boolean
  ghostSelectionStart?: boolean
  secondaryRangeStartRepresentation?: boolean
}

export const Card = styled.button.attrs<Props>(() => ({
  type: 'button',
  className: 'o-calendar__card',
}))<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  outline: 0;
  margin: 0;
  border-width: 0;
  border-radius: 3px;

  background: ${p =>
    p.cosmetic === 'edge-of-selection'
      ? applyAlphaToColor(
          p.theme.colors.$scienceBlue,
          p.secondaryRangeStartRepresentation ? 0.1 : 1,
        )
      : p.cosmetic === 'hovered'
      ? applyAlphaToColor(p.theme.colors.$scienceBlue, 0.1)
      : p.ghostSelectionStart
      ? applyAlphaToColor(p.theme.colors.$scienceBlue, 0.1)
      : 'transparent'};

  color: ${p =>
    p.cosmetic === 'edge-of-selection' && !p.secondaryRangeStartRepresentation
      ? p.theme.colors.$pureWhite
      : p.cosmetic === 'hovered'
      ? p.theme.colors.$scienceBlue
      : p.theme.colors.$nightRider};

  width: ${p => p.size || cardSize}px;
  height: ${p => p.size || cardSize}px;
  text-align: center;
  z-index: 2;

  cursor: ${p => (p.disabled ? 'default' : 'pointer')};

  &::after {
    content: '';
    display: ${p => (p.cosmetic === 'grayed-out' ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${p => applyAlphaToColor(p.theme.colors.$pureWhite, 0.85)};
    z-index: 5;
  }

  transition: background 0.2s ease-out, color 0.25s ease-out;

  &:hover {
    color: ${p =>
      p.cosmetic === 'edge-of-selection' ? p.theme.colors.$pureWhite : p.theme.colors.$scienceBlue};

    background: ${p =>
      p.cosmetic === 'edge-of-selection'
        ? applyAlphaToColor(p.theme.colors.$scienceBlue, 0.75)
        : p.cosmetic === 'normal'
        ? applyAlphaToColor(p.theme.colors.$scienceBlue, 0.1)
        : p.ghostSelectionStart
        ? applyAlphaToColor(p.theme.colors.$scienceBlue, 0.1)
        : 'transparent'};
  }

  > * {
    pointer-events: none;
  }
`

Card.displayName = 'Card'
